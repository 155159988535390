import React, {useRef} from 'react';
import {Button, Divider} from "antd";
import MapCloud, {MapCloudRef} from './MapCloud';

// 测试页面，获取url参数
const params = new URLSearchParams(window.location.search);
let sessionId = params.get('sessionId') || "mapcloud2";

const businessWs = "ws://pre-wonderland-ws.amap.com/websocket/app";
const hookUrl = "http://pre-wonderland-ws.amap.com";
const hookType = 1;

function App() {
    const mapCloudRef = useRef<MapCloudRef>(null)
    return (
        <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
            <MapCloud ref={mapCloudRef}
                      sessionId={sessionId}
                      streamUrl={"127.0.0.1:7001"}
                // zoomLevel={14}
                // center={[120.02601836678558, 30.27634501444364]}
                      businessWs={businessWs}
                      hookUrl={hookUrl}
                      hookType={hookType}
                      zoomBar={true}
                      resolutionBar={true}
                      settingBar={true}/>

            {/*<Button onClick={() => {*/}
            {/*    return mapCloudRef.current.setCenter([116.39654813914166, 39.91727443336095])*/}
            {/*}}>故宫</Button>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<Button onClick={() => {*/}
            {/*    return mapCloudRef.current.setCenter([116.39647555723808, 39.9928032148198])*/}
            {/*}}>鸟巢</Button>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<Button onClick={() => {*/}
            {/*    return mapCloudRef.current.setCenter([120.02601836678558, 30.27634501444364])*/}
            {/*}}>西溪</Button>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<Button onClick={() => {*/}
            {/*    return mapCloudRef.current.setZoom(14)*/}
            {/*}}>14比例尺</Button>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<Button onClick={() => {*/}
            {/*    return mapCloudRef.current.setZoom(18)*/}
            {/*}}>18比例尺</Button>*/}
        </div>
    );
}

export default App;
